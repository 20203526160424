import { Component, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { of } from 'rxjs';
import { debounceTime, switchMap, first, last, map } from 'rxjs/operators';
import { SESSION_STORAGE_STATE } from './../../../app.constant';
import { QuickSearch } from './../../../core/models/quick-search';
import { QuickSearchService } from './../../../core/services/quick-search.service';
import { NavService } from '../navbar/nav.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Site } from 'src/app/core/models/site';
import { NavbarComponent } from '../navbar/navbar.component';

export interface SiteVM extends Site
{
  description?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements AfterViewInit, OnInit {
  @ViewChild('openedToggle') openedToggle: ElementRef;

  @ViewChild('customerSearch', { static: false }) customerSearchCtrl: AutoCompleteComponent;

  @Input() navBar: NavbarComponent;

  selectedSite = this.siteService.currentSite;
  sites = this.siteService.sites.pipe(
    map(sites => sites.map(site => {
      return {
        ...site,
        description: `${site.id} - ${site.name}`
      } as SiteVM;
    }))
  );

  public quickSearchResult: QuickSearch[] = [];

  public virtual: any = {
    itemHeight: 28
  };

  private readonly TYPE_URL = {
    ORDERS: 'orders',
    DP: 'delivery-planner',
    PP: 'production-planner',
    PROJECT_PLANNER: 'project-planner',
    PERMIT_PLANNER: 'permit-planner',
    QUOTES: 'quotes'
  };

  public readonly FILTERS_QUICKSEARCH = this.quickSearchService.FILTERS;
  readonly keyStorageSite = SESSION_STORAGE_STATE.SITE;
  private firstLoad = true;
  private cntGetSiteInit = 0;
  private cntGetSiteAfter = 0;

  private currentSite: string;

  constructor(
    private router: Router,
    private quickSearchService: QuickSearchService,
    private navService: NavService,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.siteService.currentSite.subscribe(site => this.currentSite = site);
  }

  // public updateListSite() {
  //   this.sites = this.sites.map(site => {
  //     site.name = site.id + ' - ' + site.name;
  //     return site;
  //   });
  // }

  // public settingSite() {
  //   this.router.events.subscribe(e => {
  //     if (this.firstLoad && e instanceof ActivationEnd) {
  //       let site;
  //       if (e.snapshot && e.snapshot.params) {
  //         site = e.snapshot.params.site;
  //       }

  //       if (!site) {
  //         return;
  //       }
  //       this.firstLoad = false;
  //       this.setSite(site, this.sites);
  //     }
  //   });
  // }

  // public setSite(siteParam, sites) {
  //   if (siteParam) {
  //     this.selectedSite = sites.find(site => site.id === siteParam);
  //     let refUrl = '';
  //     if (!this.selectedSite) {
  //       this.selectedSite = sites[0];
  //       refUrl = window.location.pathname.replace(siteParam, this.selectedSite.id);
  //     }
  //     this.baseService.setCurrentSite(this.selectedSite.id);
  //     if (refUrl) {
  //       this.router.navigateByUrl(refUrl);
  //     }
  //   } else {
  //     this.selectedSite = sites[0]; // TODO select default site of user / latest site of user
  //   }
  //   // Build main menu
  //   this.navBar.buildMenu(this.selectedSite.id);
  // }

  ngAfterViewInit() {
    // if (!this.selectedSite &&  (!this.sites || (this.sites && !this.sites.length))) {
    //   this.isAuthenticated.subscribe((rs) => {
    //     if (rs && !(this.cntGetSiteInit || this.cntGetSiteAfter)) {
    //       this.cntGetSiteAfter++;
    //       this.siteService.getAll().pipe(first()).subscribe(sites => {
    //         this.sites = sites || [];
    //         this.firstLoad = false;

    //         let currentSite = this.baseService.getCurrentSite();
    //         if (!currentSite) {
    //           currentSite = this.sites[0] ? this.sites[0].id : '';
    //         }
    //         this.siteService.setCurrentSite(currentSite);
    //         this.selectedSite = this.sites.find(site => site.id === currentSite);
    //         if (this.selectedSite) {
    //           if (!this.navBar.navItems.length) {
    //             this.navBar.buildMenu(this.selectedSite.id);
    //           }
    //         }
    //       });
    //     }
    //   });
    // }
    this.registerAutocompleteHandler();
    this.navService.openedToggle = this.openedToggle;
    this.openedToggle.nativeElement.style.display = 'none';
  }

  public searchByCustomerName() {
    // Hide popup
    this.customerSearchCtrl.toggle(false);
  }

  public viewDetail(value: string) {
    if (!this.quickSearchResult) {
      return;
    }
    // Get selected object
    const selectedItem: QuickSearch = this.quickSearchResult.find((item) => item.id === value);
    if (!selectedItem) {
      return;
    }
    // Jump to related screen
    const defaultSite = this.sites[0] ? this.sites[0].id : '';
    const siteId = this.currentSite || defaultSite;
    let url: string = null;
    switch (selectedItem.cat1) {
      case this.quickSearchService.TYPE.ORDERS:
        url = this.getUrlRef(this.TYPE_URL.ORDERS, siteId, selectedItem);
        break;

      case this.quickSearchService.TYPE.PP:
        url = this.getUrlRef(this.TYPE_URL.PP, siteId, selectedItem);
        break;
      case this.quickSearchService.TYPE.PROJECT_PLANNER:
        url = this.getUrlRef(this.TYPE_URL.PROJECT_PLANNER, siteId, selectedItem);
        break;
      case this.quickSearchService.TYPE.PERMIT_PLANNER:
        url = this.getUrlRef(this.TYPE_URL.PERMIT_PLANNER, siteId, selectedItem);
        break;
      case this.quickSearchService.TYPE.DP_ORDER_ITEM:
      case this.quickSearchService.TYPE.DP_RESOURCE:
        url = this.getUrlRef(this.TYPE_URL.DP, siteId, selectedItem);
        break;
      case this.quickSearchService.TYPE.QUOTES:
        url = this.getUrlRef(this.TYPE_URL.QUOTES, siteId, selectedItem);
        break;

      default:
        break;
    }
    if (url) {
      this.router.navigateByUrl(url).then(() => {
        this.customerSearchCtrl.writeValue(null);
      });
    }
  }

  private getUrlRef(urlType: string, siteId: string, selectedItem: QuickSearch) {
    let url = `/${siteId}/${urlType}?`;
    const filter = this.quickSearchService.FILTERS;
    const val = encodeURIComponent(selectedItem.value);
    switch (selectedItem.cat2) {
      case filter.ContactName:
        url += `shipToCustomer=${val}`;
        break;
      case filter.ContactEmail:
        url += `email=${val}`;
        break;
      case filter.ContactPhone:
        // Get value by cat6/7/8
        url += `phone=${encodeURIComponent(this.getSearchPhoneContact(selectedItem))}`;
        break;
      case filter.OrderNumber:
        url += `orderNum=${val}`;
        break;
      case filter.JobNumber:
        url += `job=${val}`;
        break;
      case filter.ProjectNumber:
        url += `project=${val}`;
        break;
      case filter.QuoteNum:
        url += `orderNum=${val}`;
        break;
      case filter.LeadName:
        url += `shipToCustomer=${val}`;
        break;
      case filter.LeadEmail:
        url += `mail=${val}`;
        break;
      case filter.LeadPhone:
        url += `phone=${encodeURIComponent(this.getSearchPhoneContact(selectedItem))}`;
        break;
      default: break;
    }

    if (urlType === this.TYPE_URL.PP && selectedItem.cat3) {
      url += selectedItem.cat3 ? `&jobType=${selectedItem.cat3}` : '';
    }
    if (urlType === this.TYPE_URL.DP && selectedItem.startDate) {
      url += selectedItem.startDate ? `&startDate=${selectedItem.startDate}` : '';
    }
    return url;
  }

  private getSearchPhoneContact(item: QuickSearch) {
    const phones = [item.cat6, item.cat7, item.cat8].map(phone => phone && phone.replace(/\D/g, ''));
    const idx = phones.findIndex(phone => phone === item.value);
    switch(idx) {
      case 0: return item.cat6;
      case 1: return item.cat7;
      case 2: return item.cat8;
    }
    return item.value;
  }

  public openNav() {
    this.navService.openNav();
  }

  private registerAutocompleteHandler() {
    let searchInputVal = null;
    this.customerSearchCtrl.filterChange
      .pipe(
        debounceTime(200),
        // distinctUntilChanged(),
        switchMap((searchInput: string) => {
          if (!this.selectedSite) {
            return of(null);
          }
          searchInputVal = searchInput ? searchInput.trim() : null;
          // Check to search data
          if (!searchInputVal) {
            return of(null);
          }
          this.customerSearchCtrl.loading = true;
          return this.siteService.currentSite.pipe(
            first(),
            switchMap(site => this.quickSearchService.searchByCustomerName(site, searchInputVal))
          );
        })
      )
      .subscribe((res: QuickSearch[]) => {
        // Bind data to Autocomplete control
        this.customerSearchCtrl.loading = false;
        if (this.quickSearchResult) {
          this.quickSearchResult.length = 0;
        }

        if (res) {
          this.quickSearchResult = res;
        }

        // Only show popup when user inputs something in the control
        if (searchInputVal) {
          this.customerSearchCtrl.toggle(true);
          this.customerSearchCtrl.highlightFirst = true;
        }
      });
  }

  public changeSite($event: Site) {
    const nextSite = $event;
    sessionStorage.removeItem(SESSION_STORAGE_STATE.PRODUCTION_LIST_STATE);
    // if (!(dpData && dpData.canEdit)) {
    //   this.baseService.setCurrentSite(nextSite);
      // // Build main menu
      // this.navBar.buildMenu($event.id);
      this.router.navigateByUrl(`/${nextSite}`);
    // } else {
    //   // Handle on DP
    //   setTimeout(() => {
    //     this.selectedSite = this.sites.find(site => site.id === currentSite);

    //     // Mock to show warning
    //     this.router.navigateByUrl(`/${nextSite}`);
    //   }, 10);
    // }
  }
}
