import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Equipment } from '../models/equipment';
import { EquipmentType } from '../models/equipmentType';
import { Trailer } from '../models/trailer';
import { PersonEquipment } from '../models/personEquipment';
import { DateUtil } from '../utils/date.util';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';
import { Pair } from '../models/pair.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(private http: HttpClient) { }

  toUTCDate(date: Date): Date {
    return date ? DateUtil.toUTCDate(date) : null;
  }

  stringToLocalDate(dateStr: string | null): Date {
    return dateStr ? DateUtil.stringToLocalDate(dateStr) : null;
  }

  private localToRemote(job: Equipment): Equipment {
    return {
      id: job.id,
      code: job.code,
      description: job.description,
      make: job.make,
      model: job.model,
      year: job.year,
      color: job.color,
      vinOrSerial: job.vinOrSerial,
      plate: job.plate,
      axles: job.axles,
      gwr: job.gwr,
      tollTag: job.tollTag,
      maxLengthCapacity: job.maxLengthCapacity,
      status: job.status,
      acquiredOn: this.toUTCDate(job.acquiredOn),
      inactivedOn: this.toUTCDate(job.inactivedOn),
      disposedOn: this.toUTCDate(job.disposedOn),
      cdlRequired: job.cdlRequired,
      leased: job.leased,
      types: job.types,
      primaryType: job.primaryType
    };
  }

  private remoteToLocal(item: any): Equipment {
    return {
      ...item,

      acquiredOn: this.stringToLocalDate(item.acquiredOn),
      inactivedOn: this.stringToLocalDate(item.inactivedOn),
      disposedOn: this.stringToLocalDate(item.disposedOn)
    } as Equipment;
  }

  public get(site: string, id: string): Observable<Equipment> {
    return this.http.get(`${environment.apiUrl}${site}/equipments/${id}`).pipe(map(
      (equipment: any) => this.remoteToLocal(equipment)
    ));
  }

  public create(site: string, equipment: Equipment): Observable<Equipment> {
    const remoteEquipment = this.localToRemote(equipment);
    return this.http.post<Equipment>(`${environment.apiUrl}${site}/equipments`, remoteEquipment).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public update(site: string, equipment: Equipment): Observable<Equipment> {
    const remoteEquipment = this.localToRemote(equipment);
    return this.http.put<Equipment>(`${environment.apiUrl}${site}/equipments/${remoteEquipment.id}`, remoteEquipment).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public delete(site: string, equipment: Equipment): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}${site}/equipments/${equipment.id}`);
  }

  public deleteMany(site: string, equipments: Equipment[]): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${site}/equipments`, {
      body: equipments.map(e => ({ id: e.id }))
    });
  }

  public getAllTypes(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(`${environment.apiUrl}equipments/types`);
  }

  public getAllStatuses(): Pair<string>[] {
    return [
      { name: 'Active',   value: 'Active'   },
      { name: 'Inactive', value: 'Inactive' },
      { name: 'Disposed', value: 'Disposed' }
    ];
  }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/equipments?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data.map((e: any) => this.remoteToLocal(e)),
        total: response.total
      } as DataResult)
    ));
  }

  public fetchDefault(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/equipments/defaults?${queryStr}&getDetail=true`).pipe(map(
      (response: any) => ({
        data: response.data,
        total: response.total
      } as DataResult)
    ));
  }

  public getDefaultTrailer(site: string, state: DataSourceRequestState): Observable<Trailer[]> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get<Trailer[]>(`${environment.apiUrl}${site}/resources?${queryStr}`).pipe(map(
      (response: any) => response.data)
    );
  }

  public getDefaultUser(site: string, state: DataSourceRequestState, checkActive = true): Observable<PersonEquipment[]> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/resources?${queryStr}`).pipe(map(
      (response: any) => {
        const filterStatus = response.data.filter(item => {
          if (checkActive) {
            return item.status === UsersService.CONST.Status.active.value;
          }
          return true;
        });

        const data = filterStatus.map(item => {
          item.displayName = item.user.displayName;
          item.description = item.user.displayName;
          item.code = item.user.email;
          return item;
        });
        return data;
      })
    );
  }

  public updateEquipmentDefault(site: string, equipments: Equipment[]): Observable<any> {
    return this.http.put<Equipment>(`${environment.apiUrl}${site}/equipments/defaults`, equipments);
  }
}
