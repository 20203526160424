import { Pipe, PipeTransform } from '@angular/core';
import { ORDER_TYPES } from 'src/app/app.constant';
import { OrderItem } from 'src/app/core/models/orderItem';

@Pipe({
  name: 'deliveryCustomerName'
})
export class DeliveryCustomerNamePipe implements PipeTransform {

  transform(item: OrderItem, args?: any): any {
    if (item) {
      let rs = '';

      if (item.order.type === ORDER_TYPES.STOCK) {
        // Check warehouse
        if ((item.order && item.order.warehouse) || item.warehouse) {
          let warehouse;
          if (item.order && item.order.warehouse) {
            warehouse = item.order.warehouse;
          }

          if (!warehouse && item.warehouse) {
            warehouse = item.warehouse;
          }
          rs += warehouse.whse ? warehouse.whse.trim() : 'x';
          rs += warehouse.name ? ' - ' + warehouse.name.trim() : '';
        }
      } else {
        if (item.customer || (item.order && item.order.customer)) {
          let cus = item.customer;
          if (!cus && item.order && item.order.customer) {
            cus = item.order.customer;
          }
          rs += cus.num ? cus.num.trim() : 'x';
          rs += cus.name ? ' - ' + cus.name.trim() : ' - x';
        }
      }

      return rs;
    }
  }

}
