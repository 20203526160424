import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtil } from '../utils/date.util';
import { environment } from 'src/environments/environment';
import { Quote } from '../models/quote';
import { Document } from 'src/app/core/models/documents';
import { Logs } from '../models/logs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  private topItems = 10000;

  constructor (private http: HttpClient, private datepipe: DatePipe) {}

  private getQuoteApiUrl = (siteId: string, id?: string, queryString?:string) => `${environment.apiGatewayBaseUrl}/api/${siteId}/quotes` + (id ? '/' + id : '') + (queryString ? '?' + queryString : '');

  public fetch(siteId: string, state: DataSourceRequestState): Observable<DataResult> {
    var queryStr = `${toODataString(state, { utcDates: true })}`; // Serialize the state
    queryStr+= queryStr ? "&$count=true":"?$count=true";
    return this.http.get(this.getQuoteApiUrl(siteId, null, queryStr)).pipe(map(
      (response: any) => ({
        data: response.data ? response.data.map((e: any) => this.remoteToLocal(e)) : null,
        total: response.total
      } as DataResult)
    ));
  }

  private remoteToLocal(quote: any): Quote {
    return {
      ... quote,
      quoteDate: new Date(quote.quoteDate),
    } as Quote;
  }

  public get(quoteId: string, siteId: string): Observable<Quote> {
    return this.http.get(this.getQuoteApiUrl(siteId, quoteId)).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public getByVersionId(siteId: string, versionId: string): Observable<Quote> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/api/${siteId}/quotes/version/${versionId}`).pipe(map(
      (response: any) => response
    ));
  }

  public update(quote: Quote, siteId: string): Observable<any> {
    return this.http.put<Quote>(this.getQuoteApiUrl(siteId, quote.id), quote);
  }

  public delete(quoteId: string, siteId: string): Observable<any> {
    return this.http.delete(this.getQuoteApiUrl(siteId, quoteId));
  }

  public getDocuments(site: string, quoteId: string): Observable<Document[]> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quoteId}/documents`).pipe(
      map((response: any) => response)
    );
  }

  public getDocumentsByVersionId(site: string, versionId: string): Observable<Document[]> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/documents/version/${versionId}`).pipe(
      map((response: any) => response)
    );
  }

  public getListVersionDocument(site: string, quoteId: string): Observable<any[]> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quoteId}/documents/versions`).pipe(
      map((response: any) => response)
    );
  }

  public updateSalesman(quote: Quote, site: string, salesmanId: string): Observable<any> {
    return this.http.put<Quote>(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quote.id}/slsman/${salesmanId}`, {});
  }

  public updateStore(quote: Quote, site: string, storeId: string): Observable<any> {
    return this.http.put<Quote>(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quote.id}/store/${storeId}`, {});
  }

  public getDealIdByQuote(site: string, quoteId: string): Observable<string> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quoteId}/dealId`).pipe(
      map((response: any) => response)
    );
  }

  public getLogs(site: string, quoteId: string): Observable<Logs[]> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}${site}/quotes/${quoteId}/logs`).pipe(
      map((response: any) => {
        const data = response.map(log => {
          const date = new Date(log.changeTime);
          log.changeTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          log.timeDesc = this.datepipe.transform(date, 'E MM/dd/yyyy HH:mm:ss');
          return log;
        });
        return data;
      })
    );
  }
}
