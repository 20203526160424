import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterService } from "@progress/kendo-angular-grid";
import { FilterDescriptor } from "@progress/kendo-data-query";

@Component({
  selector: 'app-dropdownlist-header-filter',
  templateUrl: './dropdownlist-header-filter.component.html',
  styleUrls: ['./dropdownlist-header-filter.component.scss']
})
export class DropdownlistHeaderFilterComponent implements AfterViewInit {
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<string[]>();

  public value: string;

  public onValueChange(value: string): void {
    this.filterService.filter({
      filters: [{ field: this.field, operator: "eq", value: value }],
      logic: "and",
    });
  }

  public ngAfterViewInit(): void {
    const currentColumnFilter: FilterDescriptor =
      this.currentFilter.filters.find(
        (filter: FilterDescriptor) => filter.field === this.field
      );
    if (currentColumnFilter) {
      this.value = currentColumnFilter.value;
    }
  }
}
