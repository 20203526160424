import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, DataResult, toODataString, State } from '@progress/kendo-data-query';
import { Observable, firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order, RequestTransitionUrl } from 'src/app/core/models/order';
import { DateUtil } from '../utils/date.util';
import { OrderItem } from '../models/orderItem';
import { ItemPlan } from '../models/resourcePlan';
import { Job } from '../models/job';
import { JobPlan } from '../models/jobPlan';
import { Note } from '../models/notes';
import { Salesman } from '../models/salesman';
import { Department } from '../models/department';
import { ChangeLog } from '../models/changeLog';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Item } from '../models/item';
import { Reason } from '../models/reason';
import { Document, DocumentSign, SignatureRequest } from '../models/documents';
import { PaymentSchedule } from '../models/payment-schedule';
import { Attachment } from '../models/attachment';
import { ChangeRequest } from '../models/changeRequest';
import { Warehouse } from '../models/warehouse';
import { OrderInfo } from '../models/order-info';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private topItems = 10000;

  constructor (private http: HttpClient, private datepipe: DatePipe) { }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state, { utcDates: true })}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/orders?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data ? response.data.map((e: any) => this.remoteToLocal(e)) : null,
        total: response.total
      } as DataResult)
    ));
  }

  private remoteToLocal(order: any): Order {
    return {
      ... order,

      orderDate: DateUtil.stringToLocalDate(order.orderDate),
      startDate: DateUtil.stringToLocalDate(order.startDate),
      dueDate: DateUtil.stringToLocalDate(order.dueDate),
      shipDate: DateUtil.stringToLocalDate(order.shipDate),
      nextShipDate: DateUtil.stringToLocalDate(order.nextShipDate),
      createdAt: DateUtil.stringToLocalDateTime(order.createdAt),
      updatedAt: DateUtil.stringToLocalDateTime(order.updatedAt),

      items: order.items ? order.items.map((item: any) => {
        return {
          ... item,

          dueDate: DateUtil.stringToLocalDate(item.dueDate),
          shipDate: DateUtil.stringToLocalDate(item.shipDate),

          itemPlan: item.itemPlan ? {
            ... item.itemPlan,

            startDate: DateUtil.stringToLocalDate(item.itemPlan.startDate),
            endDate: DateUtil.stringToLocalDate(item.itemPlan.endDate),
          } as ItemPlan : null,
          job: item.job ? {
            ... item.job,

            jobPlan: item.job.jobPlan ? {
              ... item.job.jobPlan,

              startDate: DateUtil.stringToLocalDate(item.job.jobPlan.startDate),
              endDate: DateUtil.stringToLocalDate(item.job.jobPlan.endDate),
              actualStartDate: DateUtil.stringToLocalDate(item.job.jobPlan.actualStartDate),
              actualEndDate: DateUtil.stringToLocalDate(item.job.jobPlan.actualEndDate),
            } as JobPlan : null
          } as Job : null,

          subTotal: item.subTotal ? item.subTotal : null
        } as OrderItem;
      }) : null
    } as Order;
  }

  public get(site: string, orderNum: string): Observable<Order> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}`).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public getByVersionId(site: string, masterId: string, versionId: string): Observable<Order> {
    return this.http.get(`${environment.apiUrl}${site}/orders/version/${masterId}/${versionId}`).pipe(map(
      (response: any) => response
    ));
  }

  public update(site: string, order: Order): Observable<any> {
    return this.http.put<Order>(`${environment.apiUrl}${site}/orders/${order.num}`, order);
  }

  public deleteMany(site: string, orders: Order[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/orders`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: orders
    });
  }

  public getTasks(site: string, orderNum: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/tasks`).pipe(map(
      (response: any) => ({
        data: response,
        total: response.length
      } as DataResult)
    ));
  }

  public createTask(site: string, orderNum: string, task: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${site}/orders/${orderNum}/tasks`, task);
  }

  public generateDefaultTask(site: string, orderNum: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/tasks/default`).pipe(map(
      (response: any) => ({
        data: response,
        total: response.length
      } as DataResult)
    ));
  }

  public updateTaskMany(site: string, orderNum: string, tasks: any[]): Observable<any> {
    return this.http.put<any[]>(`${environment.apiUrl}${site}/orders/${orderNum}/tasks`, tasks);
  }

  public deleteTask(site: string, orderNum: string, id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${site}/orders/${orderNum}/tasks/${id}`);
  }

  public deleteTaskMany(site: string, orderNum: string, tasks: any[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/orders/${orderNum}/tasks`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: tasks
    });
  }

  public getNotes(site: string, order: Order): Observable<Note[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${order.num}/notes`).pipe(map(
      (response: any) => response.map(note => {
        return {
          ... note,
          line: note.line ? note.line : 0,
          createdAt: DateUtil.stringToLocalDate(note.createdAt),
          updatedAt: DateUtil.stringToLocalDate(note.updatedAt),
        } as Note;
      })
    ));
  }

  public saveNotes(site: string, orderNum: string, notes: Note[]): Observable<any> {
    return this.http.put<Note[]>(`${environment.apiUrl}${site}/orders/${orderNum}/notes`, notes);
  }

  public deleteNotes(site: string, orderNum: string, notes: Note[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/orders/${orderNum}/notes`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: notes
    });
  }

  public getAssignees(site: string, state: DataSourceRequestState): Observable<any[]> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/resources?${queryStr}`).pipe(map(
      (response: any) => {
        const data = response.data.map(item => {
          item.displayName = item.user.displayName;
          return item;
        });
        return data;
      })
    );
  }

  public viewInERP(site: string, orderNum: string) {
    const url = `${environment.erpBaseUrl}/WSWebClient/Default.aspx?config=${site}`
      + `&form=CustomerOrdersQuickEntry(FILTER(CoNum='${orderNum}')SETVARVALUES(InitialCommand=Refresh))`;
    window.open(url, '_blank');
  }

  public getMaxLine(site: string, isNewMode: boolean, orderNum: string): Observable<number> {
    if (isNewMode) {
      return of(0);
    }
    return this.http.get(`${environment.apiUrl}${site}/orderItems/${orderNum}/maxLine`).pipe(map(
      (maxLine: any) => maxLine
    ));
  }

  private remoteToLocalSearchOrderItem(orderItem: any): OrderItem {
    return {
      ... orderItem,
      dueDate: DateUtil.stringToLocalDateTime(orderItem?.dueDate),
      shipDate: DateUtil.stringToLocalDateTime(orderItem?.shipDate),
      itemPlan: {
        ...orderItem.itemPlan,
        oldStartDate: DateUtil.stringToLocalDate(orderItem?.itemPlan?.oldStartDate),
        startDate: DateUtil.stringToLocalDate(orderItem?.itemPlan?.startDate),
        endDate: DateUtil.stringToLocalDate(orderItem?.itemPlan?.endDate),

      }
    } as OrderItem;
  }

  public searchOrderItems(site: string, state: State): Observable<OrderItem[]> {
    const qstr = `${toODataString(state, {utcDates: true})}`;
    return this.http.get<OrderItem[]>(`${environment.apiUrl}${site}/orderItems?${qstr}`).pipe(map(
      (response: any) => response.map((e: any) => this.remoteToLocalSearchOrderItem(e))
    ));
  }

  public searchOrderItemsAsync(site: string, state: State): Promise<OrderItem[]> {
    return firstValueFrom(this.searchOrderItems(site, state));
  }

  public updateItemPlanStatus(site: string, orderItem: OrderItem, itemplan: ItemPlan): Observable<any> {
    const url = `${environment.apiUrl}${site}/orderItems/${orderItem.order.num}-${orderItem.line}-${orderItem.release}`;
    return this.http.put<ItemPlan>(url, itemplan);
  }

  public getPhones(customer: any, warehouse: any) {
    customer = customer ? customer : warehouse;
    if (customer) {
      return [customer.phone1, customer.phone2, customer.phone3, customer.phone].filter(phone => phone);
    }
  }

  public getSalesmans(site: string): Observable<Salesman[]> {
    // TODO filter
    return this.http.get(`${environment.apiUrl}${site}/salesmans?$top=${this.topItems}&$orderby=num`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getDepartments(site: string): Observable<Department[]> {
    // TODO filter
    return this.http.get(`${environment.apiUrl}${site}/departments?$top=${this.topItems}&$orderby=num`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getChangeLogs(site: string, orderNum: string): Observable<ChangeLog[]> {
    // TODO filter
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/changelogs`).pipe(
      map((response: any) => {
        const data = response.map(log => {
          const date = new Date(log.time);
          log.time = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          log.timeDesc = this.datepipe.transform(date, 'E MM/dd/yyyy HH:mm:ss');
          return log;
        });
        return data;
      })
    );
  }

  public getDetailDocument(docId: string): Observable<DocumentSign> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}documents/${docId}`).pipe(
      map((response: any) => response)
    );
  }

  public downloadDocument(fileId: string): Observable<Blob> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}files/${encodeURIComponent(fileId)}/download`, {
      responseType: 'blob'
    });
  }

  public getAttachmentByPayment(site: string, order: Order, paymentId: string): Observable<Attachment[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${order.num}/payments/${paymentId}/attachments`).pipe(map(
      (response: Attachment[]) => response
    ));
  }

  public downloadProofOfPayment(site: string, orderNum: string, paymentId: string, id: string): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/payments/${paymentId}/attachments/${id}`, {
      responseType: 'blob'
    });
  }

  public signDocument(docId: string, sign: SignatureRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}documents/${docId}/sign`, sign);
  }

  public getListVersionDocument(site: string, orderNum: string): Observable<any[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/documents/versions`).pipe(
      map((response: any) => response)
    );
  }

  public getDocuments(site: string, orderNum: string): Observable<Document[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/documents`).pipe(
      map((response: any) => response)
    );
  }

  public getListPaymentByOrderNum(site: string, orderNum: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/payments`).pipe(
      map((response: any) => ({
        data: response
          ? response.map((e: any) => {return {
            ... e,
            type: e.type == 'Credit' ? 'Card' : e.type,
            paymentDate: DateUtil.stringToLocalDate(e.date)
          }})
          : null,
        total: response.length} as DataResult)
      )
    );
  }

  public getListPaymentSchedule(site: string, orderNum: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/paymentschedules`).pipe(
      map((response: any) => ({
        data: response
          ? response.map((e: any, idx) => {return {
            ... e,
            dueDate: DateUtil.stringToLocalDate(e.dueDate),
            index: idx
          }})
          : null,
        total: response.length} as DataResult)
      )
    );
  }

  public createPaymentSchedule(site: string, orderNum: string, schedule: PaymentSchedule): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orders/${orderNum}/paymentschedules`, schedule);
  }

  public updatePaymentSchedule(site: string, scheduleId: string, schedule: PaymentSchedule): Observable<any> {
    return this.http.put<PaymentSchedule>(`${environment.apiUrl}${site}/orders/paymentschedules/${scheduleId}`, schedule);
  }

  public deletePaymentSchedules(site: string, ids: string[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/orders/paymentschedules`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: ids
    });
  }

  public generateDefaultPaymentSchedule(site: string, orderNum: string): Observable<DataResult> {
    return this.http.post(`${environment.apiUrl}${site}/orders/${orderNum}/paymentschedules/generate`, {}).pipe(
      map((response: any) => ({
        data: response
          ? response.map((e: any, idx) => {return {
            ... e,
            dueDate: DateUtil.stringToLocalDate(e.dueDate),
            index: idx
          }})
          : null,
        total: response.length} as DataResult)
      )
    );
  }

  public syncPayment(site: string, orderNum: string, paymentId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${site}/orders/${orderNum}/payments/${paymentId}/sync`, null);
  }

  public getErpPostPayment(site: string, orderNum: string): Observable<number> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/post-payments`).pipe(
      map((response: any) => response)
    );
  }

  public getErpDownPayment(site: string, orderNum: string): Observable<number> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/down-payments`).pipe(
      map((response: any) => response)
    );
  }

  public generateErpLine(site: string, orderNum: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${site}/orders/${orderNum}/generate-lines`, null);
  }

  public getItems(site: string): Observable<Item[]> {
    return this.http
      .get(`${environment.apiUrl}${site}/items?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public updateCrmRef(site: string, orderNum: string, orderLine: number, orderRelease: number, crmRef: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${site}/orderitems/${orderNum}-${orderLine}-${orderRelease}/crmRef`, {crmRef});
  }

  public getValidCrmRef(site: string, orderNum: string, orderLine: number, orderRelease: number, crmRef: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${site}/orderitems/${orderNum}-${orderLine}-${orderRelease}/is-valid-crm-ref`, {crmRef});
  }

  public getValidCrmRefAsync(site: string, orderNum: string, orderLine: number, orderRelease: number, crmRef: string): Promise<any> {
    return firstValueFrom(this.getValidCrmRef(site, orderNum, orderLine, orderRelease, crmRef));
  }

  public getOrderReasons(): Observable<Reason[]> {
    return this.http.get(`${environment.apiUrl}orders/reasons`).pipe(
      map((response: any) => response)
    );
  }

  public getCOCReport(templateId:string, req: any): Observable<Blob> {
    return this.http.post(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}templates/${templateId}/generate?download=true&upload=false`,
      req,
      { responseType: 'blob', observe: 'response' }).pipe(
      map((result: any) => {
        return new Blob([result.body], {type: 'application/pdf'});
      })
    );
  }

  public getReceiptPaymentReport(url: string): Observable<Blob> {
    return this.http.get(`${url}`, {
    }).pipe(
      map((result: any) => {
        return new Blob([result.body], {type: 'application/pdf'});
      })
    );
  }

  public getCPLink(site: string, orderNum: string, line: number = null): Observable<any> {
    let filter = !!line ? `?line=${line}` : '';
    return this.http.post<any>(`${environment.apiUrl}${site}/orders/${orderNum}/cp-link${filter}`, {});
  }

  public generateJob(site: string, orderNum: string, orderLine: number, orderRelease: number): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orderItems/${orderNum}-${orderLine}-${orderRelease}/create-job`, {});
  }

  public getDocumentsByVersionId(site: string, masterId: string, versionId: string): Observable<Document[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/documents/version/${masterId}/${versionId}`).pipe(
      map((response: any) => response)
    );
  }

  public getDocumentsByVersionIdAsync(site: string, masterId: string, versionId: string): Promise<Document[]> {
    return firstValueFrom(this.getDocumentsByVersionId(site, masterId, versionId));
  }

  public updateSalesman(order: Order, site: string, salesmanId: string): Observable<any> {
    return this.http.put<Order>(`${environment.apiUrl}${site}/orders/${order.num}/slsman/${salesmanId}`, {});
  }

  public updateStore(order: Order, site: string, storeId: string): Observable<any> {
    return this.http.put<Order>(`${environment.apiUrl}${site}/orders/${order.num}/store/${storeId}`, {});
  }

  public getChangeRequests(site: string, orderNum: string): Observable<ChangeRequest[]> {
    return this.http.get(`${environment.apiUrl}${site}/orders/${orderNum}/change-requests`).pipe(
      map((response: any) => {
        const data = response.map(order => this.remoteToLocal(order));
        return data;
      })
    );
  }

  public approveChangeRequest(site: string, crOrderNum: string): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orders/${crOrderNum}/cr_approve`, {});
  }

  public cancelChangeRequest(site: string, crOrderNum: string): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orders/${crOrderNum}/cr_cancel`, {});
  }

  public createDescChangeRequest(site: string, desc: string): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orders/descriptions`, {
      description: desc
    });
  }

  public updateDescChangeRequest(site: string, crOrderNum: string, desc: string): Observable<any> {
    return this.http.put<PaymentSchedule>(`${environment.apiUrl}${site}/orders/${crOrderNum}/descriptions`, {
      description: desc
    });
  }

  public overwriteBomChangeRequest(site: string, crOrderNum: string, orderLine: number, orderRelease: number): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orderItems/${crOrderNum}-${orderLine}-${orderRelease}/override-bom`, {});
  }

  public orphanCreateJob(site: string, crOrderNum: string, orderLine: number, orderRelease: number): Observable<any> {
    return this.http.post<PaymentSchedule>(`${environment.apiUrl}${site}/orderItems/${crOrderNum}-${orderLine}-${orderRelease}/orphan-create-job`, {});
  }

  public getTransitionUrl(request: RequestTransitionUrl): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}oms-link`, request);
  }

  public getWarehouses(site: string): Observable<Warehouse[]> {
    return this.http
      .get(`${environment.apiUrl}${site}/whses?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getWarehousesAsync(site: string): Promise<Warehouse[]> {
    return firstValueFrom(this.getWarehouses(site));
  }

  public getWarehouse(site: string, whse: string): Observable<Warehouse> {
    return this.http
      .get(`${environment.apiUrl}${site}/whses/${whse}`)
      .pipe(map((reponse: any) => reponse));
  }

  public stopPreowned(site: string, orderNum: string, orderInfo: OrderInfo) : Observable<any> {
    return this.http.post(`${environment.apiUrl}${site}/orders/${orderNum}/stop-order`, orderInfo);
  }

  public changeDocumentStatus(site: string, orderNum: string) : Observable<any> {
    return this.http.put(`${environment.apiUrl}${site}/orders/${orderNum}/documents/ready-status`, {});
  }
  
  public regenerateDocument(site: string, orderNum: string) : Observable<any> {
    return this.http.post(`${environment.apiUrl}${site}/orders/${orderNum}/documents/regenerate`, {});
  }
}